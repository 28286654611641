<template>
  <div class="report-container">
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Row class="search-row">
        <Col span="4">
          <span class="sub-label">省份：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.pid"
                    placeholder="请选择..."
                    @on-change="changePro">
              <Option :value="v.id"
                      v-for="v in proList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">城市：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.cid"
                    placeholder="请选择..."
                    @on-change="changeCity">
              <Option :value="v.id"
                      v-for="v in cityList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">门店：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.sid"
                    placeholder="请选择...">
              <Option :value="v.id"
                      v-for="v in storeList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">会员：</span>
          <div class="sub-content">
            <Input v-model="searchData.keyword" placeholder="输入顾客名称"></Input>
          </div>
        </Col>
        <Col span="4" offset="1">
          <Button type="primary" @click="handleSearch">搜索报告</Button>
        </Col>
      </Row>
      <Row class="search-row">
        <Col span="4">
          <span class="sub-label">时间：</span>
          <div class="sub-content">
            <DatePicker
              type="daterange"
              placeholder="选择时间"
              style="width: 100%;"
              :options="dateOptions"
              @on-change="handleDateChange"></DatePicker>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">快捷时间：</span>
          <div class="sub-content">
            <Select v-model="searchData.month_flag" style="width: 100%;">
              <Option :value="0">默认</Option>
              <Option :value="1">最近一个月</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">报告类型：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.type"
                    placeholder="请选择..."
                    @on-change="handleTypeChange">
              <Option :value="v.type"
                      v-for="v in reportType"
                      :key="v.type">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1" v-if="reportName.length > 0">
          <span class="sub-label">报告名称：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.pic_type"
                    placeholder="请选择...">
              <Option :value="v.id"
                      v-for="v in reportName"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">服务员工：</span>
          <div class="sub-content">
            <Select clearable filterable
                    v-model="searchData.staff"
                    placeholder="请选择...">
              <Option :value="v.name"
                      v-for="v in staffList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <Button type="info" @click="addReport">批量上传</Button>
        </Col>
      </Row>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
        <span>检测报告</span>
        <div class="action-bar" v-if="selectedDataItem.length > 0">
          <Button type="success" @click="handleDownload">批量下载</Button>
          <Button type="error" @click="handleDelete" v-show="power.indexOf('会员管理--删除报告') > -1">批量删除</Button>
        </div>
      </h2>
      <div class="content-body">
        <Table
          :columns="reportColumns"
          :data="reportList"
          ref="table"
          @on-selection-change="handleDataSelect"></Table>
        <div>
          <span class="records">共 {{totalReports}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="totalReports"
              :current="searchData.page"
              :page-size="searchData.size"
              @on-change="changeDataPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="上传报告"
      class-name="vertical-center-modal">
      <Form :model="formData" :label-width="100">
        <Form-item label="报告类型">
          <Select v-model="formData.type" @on-change="onTypeChange">
            <Option
              :value="type.type"
              v-for="type in reportType" 
              :key="type.type">{{ type.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="报告名称" v-if="formData.type && reportType[formData.type - 1].child.length > 0">
          <Select v-model="formData.bgzl">
            <Option
              :value="sub.id"
              v-for="sub in reportType[formData.type - 1].child"
              :key="sub.id">{{ sub.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="文件">
          <Upload action="/api/bg/pladd"
                  name="photo"
                  ref="upload"
                  multiple
                  :data="formData"
                  :max-size="1024 * 30"
                  :format="['png','jpg','jpeg','pdf']"
                  :on-format-error="handleFormatError"
                  :on-success="handleUploadSuccess"
                  :on-exceeded-size="handleMaxSize"
                  :before-upload="handleBeforeUpload">
            <Button icon="ios-cloud-upload-outline">上传</Button>
          </Upload>
          <span style="font-size:12px;color:red;">* 仅能上传文件大小为≤30MB，PDF、PNG、JPG格式文件</span>
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>

<script>
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			searchData: {
				page: 1,
				size: 10,
				pid: '',
				cid: '',
				sid: '',
				start_time: null,
				end_time: null,
				month_flag: 0,
				type: '',
				pic_type: '',
				keyword: '',
				staff: '',
			},
			storeList: [],
			proList: [],
			cityList: [],
			reportType: [],
			reportName: [],
			totalReports: 0,
			selectedDataItem: [],
			reportList: [],
			reportColumns: [
				{ type: 'selection', width: 60, align: 'center' },
				{
					title: '检测日期',
					align: 'center',
					render(h, params) {
						return h('span', params.row.cjrq.substring(0, 10));
					},
				},
				{
					title: '上传日期',
					align: 'center',
					render(h, params) {
						return h('span', params.row.scrq.substring(0, 10));
					},
				},
				{ title: '报告类型', key: 'bglx', align: 'center' },
				{ title: '报告类别', key: 'bglb', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '顾客名称', key: 'gkmc', align: 'center' },
				{ title: '报告描述', key: 'bgms', align: 'center' },
				{ title: '服务员工', key: 'jcs', align: 'center' },
				{ title: '所属分院', key: 'md', align: 'center' },
				{
					align: 'center',
					title: '报告预览',
					render: (h, params) => {
						const reportLink = params.row.url;
						let placeholderPath = reportLink;
						if (reportLink.match(/\.pdf$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder-pdf.jpg', import.meta.url)
								.href;
						} else if (!reportLink.match(/\.(png|jpe?g|gif|svg)$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder.png', import.meta.url).href;
						}
						return h(
							'a',
							{
								attrs: {
									href: reportLink,
									target: '_blank',
								},
							},
							[
								h('img', {
									attrs: {
										src: placeholderPath,
									},
									style: {
										padding: '10px 0',
										width: '100px',
										height: '100px',
										'object-fit': 'contain',
									},
								}),
							],
						);
					},
				},
				{
					align: 'center',
					title: '操作',
					width: 140,
					render: (h, params) => {
						return h('div', [
							h('a', {
								class: 'icon_edit',
								style: {
									margin: '0 5px',
								},
								on: {
									click: () => {
										this.$router.push({
											name: 'memberReportAdd',
											params: { id: params.row.id, member_id: params.row.member_id },
										});
									},
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-eye',
									size: 22,
									color: '#fdad00',
								},
								style: {
									margin: '0 5px',
									cursor: 'pointer',
								},
								on: {
									click: () => {
										const data = params.row;
										if (data.bglx === '问卷报告') {
											let type = '';
											switch (data.bglb) {
												case 'DISC调查问卷':
													type = 'disc';
													break;
												case '健康资源盘点问卷':
													type = 'pckeb';
													break;
												default:
													type = 'abo';
													break;
											}
											window.open(`//wenjuan.sskh.net/#/report/${type}/${data.id}`, '_blank');
										} else {
											this.$router.push({
												name: 'memberReportDetail',
												params: { id: data.id, member_id: data.member_id },
											});
										}
									},
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: {
									margin: '0 5px',
									cursor: 'pointer',
								},
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = params.row.url;
										a.style.display = 'none';
										a.setAttribute('download', params.row.bgmc);
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			showModal: false,
			formData: {
				type: '',
				bgzl: '',
			},
			uploadList: [],
			staffList: [],
			dateOptions: {
				disabledDate(date) {
					return date && date.valueOf() > Date.now();
				},
			},
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		this.getReportType();
		this.handleSearch();
		memberService.getServiceStaffList().then((data) => {
			this.staffList = data;
		});
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		getReportType() {
			memberService.getReportType().then((data) => {
				this.reportType = data;
			});
		},
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		changeDataPage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.id);
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.searchData.start_time = val[0];
				this.searchData.end_time = val[1];
			} else {
				this.searchData.start_time = null;
				this.searchData.end_time = null;
			}
		},
		handleTypeChange(val) {
			this.searchData.pic_type = '';
			const checked = this.reportType.filter((item) => item.type === val);
			if (checked.length > 0) {
				this.reportName = checked[0].child;
			} else {
				this.reportName = [];
			}
		},
		handleSearch() {
			memberService.getReportList(this.searchData).then((data) => {
				this.reportList = data.list;
				this.totalReports = data.row_size;
			});
		},
		handleDelete() {
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除所选报告吗？',
				onOk: () => {
					memberService.deleteReport({ ids: this.selectedDataItem.join(',') }).then(() => {
						this.$Message.success('删除成功');
						this.handleSearch();
						this.selectedDataItem = [];
					});
				},
			});
		},
		handleDownload() {
			this.$Modal.confirm({
				title: '确认',
				content: '确定批量下载所选报告吗？',
				onOk: () => {
					memberService.downloadReport({ ids: this.selectedDataItem.join(',') }).then((data) => {
						const a = document.createElement('a');
						a.href = data.url;
						a.style.display = 'none';
						a.setAttribute('download', '报告.zip');
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					});
				},
			});
		},
		addReport() {
			this.showModal = true;
		},
		onTypeChange() {
			this.formData.bgzl = '';
		},
		handleUploadSuccess(response) {
			if (response.c !== 0) {
				const uploadRef = this.$refs.upload;
				this.$Message.error(response.m);
				uploadRef.fileList.forEach((file) => {
					if (file.response.c !== 0) uploadRef.handleRemove(file);
				});
			}
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 30 M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg、jpeg 或 pdf 格式的文件。',
			);
		},
		handleBeforeUpload() {
			let passed = true;
			if (!this.formData.type) {
				passed = false;
			} else {
				if (this.reportType[this.formData.type - 1].child.length > 0 && !this.formData.bgzl) {
					passed = false;
				}
			}
			if (!passed) {
				this.$Message.warning('请选择报告类型');
			}
			return passed;
		},
	},
};
</script>

<style lang="less" scoped>
.search-box {
  margin-top: 15px;
  padding-bottom: 10px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 80px;
}
</style>
